import React,{useState} from 'react';
import styled from '@emotion/styled';
import {graphql,useStaticQuery} from 'gatsby'
import BackgroundImage from 'gatsby-background-image';
import Formulario from './form';
import Social from './social'

const Contact = styled.div`
 
  max-width:1200px;
  margin:100px auto;
  display:flex;
  justify-content:center;
  flex-direction:column;
  opacity:${props => props.loading === "true" ? '.5' :  '1'};
  


`
const Write = styled.div`
    display:flex;
    justify-content:center;
    width:100%;
    @media (max-width:768px){
    flex-direction: column;
  }
    

`

const Titulo = styled.div`
    padding:1rem;
    h3{
      text-align:center;
      font-size:4.5rem;
      color:white;
      margin:20px 0px;
    }
    p{
      color:white;
      font-size:1.9rem;
      text-align:center;
    }
    @media (max-width:480px){
          h3{
              font-size:4rem;
          }
          p{
              font-size:1.7rem;
              font-weight:bold;
              text-align:center;
          }
    }
`
const Container = styled.div`
 background-image: 
              linear-gradient(to top, rgba(34,49,63,.7),rgba(34,49,63,.7));

.spinner {
  margin: 100px auto 0;
  width: 100px;
  text-align: center;
  position: absolute;
  top:45%;
  left:50%;
}

.spinner > div {
  width: 22px;
  height: 22px;
  background-color: #f9f7f7;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}
`

const ContactForm = () => {
  
  const [loading, setLoading] = useState("false");
      
  const {image} = useStaticQuery(graphql`
  query{
      image: file(relativePath: {eq:"contact.jpg"}){
      sharp:childImageSharp{
        fluid(maxWidth:1000){
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
  `)


      return(
        <section>

        <BackgroundImage tag='section' fluid={image.sharp.fluid} fadeIn='soft'>
          <Container >
            <Contact loading={loading}>
              <Titulo>
                  <h3>Contactenos</h3>
                  <p>¿Querés saber más acerca de nuestros servicios o tenés alguna sugerencia?</p>
              </Titulo>
              <Write>
                 <Formulario  setLoading={setLoading}/>
                 <Social/>
              </Write>
              
            </Contact>
            {loading === 'true' ? (<div class="spinner">
              <div class="bounce1"></div>
              <div class="bounce2"></div>
              <div class="bounce3"></div>
              </div>) : null}  
            </Container>
            
          </BackgroundImage>
          </section>
      )
    
}
 
export default ContactForm;