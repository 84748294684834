import React from 'react';
import styled from '@emotion/styled';


const Redes = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    h4{
        color:#f9f7f7;
        font-size:2.2rem;
    }
    .list{
        display:flex;
        flex-direction:column;
        justify-content:flex-start;
        align-items:flex-start;
        margin-top:2rem;
        @media (max-width:768px){
            margin-top:0;
        }
        
    }
`

const Datos = styled.div`
    display:flex;
    flex-direction:center;
    align-items:flex-start;
    
    font-size:1.8rem;
    color:#f9f7f7;
    transition:all .5s;
    a{
        color:#f9f7f7;
        :hover{
            color:#112d4e;
        }
    }
    p{
        margin-top:8px;
        font-size:2rem;
    }
    .last{
        margin-bottom:4rem;
    }
    .text{
     align-items:flex-start;
    }
    
    :hover{
        color:#112d4e;
    }
    @media (max-width:768px){
        padding:1rem;
        font-weight:bold;
        
    }

`

const Icon = styled.div`
    margin:1rem;
`

const Social = () => {
    
    return (  
        <Redes>
            <div className='title'>
            <h4>Estudio Contable Lemberger</h4>
            </div>
            <div className='list'>
            <Datos>
            <Icon><span className='icon-mobile'></span></Icon><div className='text'><p> <a href='https://api.whatsapp.com/send?phone=+5492233069641' target="_blank" rel="noopener noreferrer" >223-3069641</a></p></div>
            </Datos>
            <Datos>
            <Icon><span className="icon-mail2"></span></Icon><div className='text'><p> <a href='mailto:mailto:info@estudiolemberger.com.ar'>info@estudiolemberger.com</a></p></div>
            </Datos>
            <Datos>
            <Icon className='last'><span className="icon-office"></span></Icon><div className='text'><p className='last'>Jara 131 Mar del Plata </p></div>
            </Datos>
            </div>
           
        </Redes>
    );
}
 
export default Social;