import React from 'react';
import axios from 'axios';
import {Formik,Form,Field,ErrorMessage} from 'formik';
import * as Yup from 'yup';
import styled from '@emotion/styled';
import swal from 'sweetalert';

const Contact = styled.div`
width:50%;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
margin:20px 0 40px 0;
@media (max-width:768px){
  width:100%;
  padding:1rem;
}



  form{
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-right:6rem;
    @media (max-width:1024px){
      margin-right:0;
    }
  }
  
  input{
    width:100%;
    padding:1rem;
    border-radius:1rem;
    border:1px solid #3f72af;
    :focus{
      border:2px solid #112d4e;
    }
  }
  textarea{
    width:100%;
    resize:none;
    padding:1.5rem;
    border-radius:1rem;
    border:1px solid #3f72af;
    height:175px;
    overflow: scroll;
    resize: none;
    :focus{
      border:2px solid #112d4e;
    }
  }
  .form-row{
    padding:.5rem;
    margin:5px;
    position:relative;
    width:95%;
    display:flex;
    flex-direction:column;
   
  }

  .btn-submit{
    font-weight:bold;
          color:white;
          padding:1rem;
          margin:.5rem;
          background-color:#3f72af;
          text-align:center;
          border-radius:1rem;
          transition:all .9s;
          width:100%;

          :hover{
              background-color:#dbe2ef;
              color:#3f72af;
          }
  }
  .error{
    width:80%;
    font-size:1.3rem;
    background-color:red;
    color:white;
    text-align:center;
    margin:2px auto;
    border-radius:.5rem;
    
    
  }
  @media (max-width:480px){
        
          .form-row{
              font-size:1.8rem;
          }
      }

`

const Formulario = ({setLoading}) => {
    

    const formSchema =Yup.object().shape({
        name: Yup.string()
          .required("Este campo es requerido"),
        email: Yup.string()
          .required("El email es requerido")
          .email("Correo electronico Invalido")
          .max(255, "Maximo 255 caracteres"),
        cel: Yup.number()
          .required("Numero requerido")
          .min(8,"Debe ingresar un numero valido"),
        mensaje: Yup.string()
         .required('Escribanos su consulta')
         .min(20, 'Escriba un poco mas') 
      })

      const handelSubmit =async values =>{
        setLoading("true")
        
        const res = await axios.post("https://formspree.io/mrgywbkw",values);
        if(res.status === 200){
          
          setLoading("false")
          swal("Mensaje enviado", "Nos pondremos en contacto con usted a la brevedad", "success");
        }else{ 
          swal("Upss!!!", "Parece que hubo un error en el servidor, intentelo en unos instantes", "error");
          setLoading("false")
          }
        }

    return (
        <Contact>
          
          <Formik
            initialValues={{
              name: '',
              email: '',
              cel: '',
              mensaje:'',
            }}
            validationSchema={formSchema}
            onSubmit={async (values,{resetForm}) => {
             handelSubmit(values);
              resetForm({values:''});
            }}
          >
          {({ isSubmitting }) => (
            <Form>
            
            <div className='form-row'>
              <label htmlFor="name" >
              <Field name="name" id='name' placeholder="Nombre y Apellido" lable='name'/>
              </label>
              
          
              <ErrorMessage
                       name='name'
                       component='div'
                       className='error'
                      />
            </div>
           
            <div className='form-row'>
              <label htmlFor="email">
                <Field name="email" id='email' placeholder="Email" label='email' />
                </label>
              
              <ErrorMessage
                       name='email'
                       component='div'
                       className='error'
                      />
            </div>
            
            
            <div className='form-row'>
              <label htmlFor="cel">
                <Field name="cel" id='cel' placeholder="Numero de contacto" type="number" label='cel'/>
                </label>
              
              <ErrorMessage
                       name='cel'
                       component='div'
                       className='error'
                      />
            </div>
            
            <div className='form-row'>
              <label htmlFor='mensaje'>
                <Field name='mensaje' id='mensaje' placeholder='Escribanos su consulta..' as='textarea' label='mensaje'/>
                </label>
              
              <ErrorMessage
                       name='mensaje'
                       component='div'
                       className='error'
                      />
            </div>
            
            <div className='form-row'>
              <input className='btn-submit' type="submit" disabled={isSubmitting}/>
                
              
            </div>
            
          </Form>
        )}
      </Formik>
    </Contact>
    );
}
 
export default Formulario;